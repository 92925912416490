import { Controller } from "@hotwired/stimulus"
import Reveal from 'reveal.js';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';

export default class RevealjsController extends Controller {
  connect() {
    this.deck = new Reveal(this.element, {
      plugins: [Markdown]
    });
    this.deck.initialize();
  }

  disconnect() {
    this.deck.destroy();
  }
}
